import React from "react"
import Layout from "../components/layout"

import Clutch from "../components/clutch"
import Amazon1 from "../images/amazon/amazon1.jpg"
import Plant from "../images/amazon/plant.gif"
import Appscreen from "../images/amazon/app-screen.png"
import Moving_img1 from "../images/amazon/moving-img1.png"
import Challenge_sec from "../images/amazon/challenges-section-image.jpg"
import Arrow_wh from "../images/amazon/arrow-wh.png"

import Scroll_top_img from "../images/scroll-arrow.png"
import {Helmet} from "react-helmet/es/Helmet"

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'

export default () => (
  <Layout>
    <Helmet>
      <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
      <meta name="description"
            content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
      <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
      <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
      <meta name="description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
      <meta property="og:site_name" content="Cueblocks"/>
      <meta property="fb:app_id" content="289086684439915"/>
      <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
      <meta property="og:title"
            content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
      <meta property="og:description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
      <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
      <meta name="twitter:title"
            content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
      <meta name="twitter:site" content="@cueblocks"/>
      <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
    </Helmet>
    <div className=" cb_expertiseInner">
      <div className="case_study_page amazon-page">
        <div className="case-wrap  ">
          <div className="ww-sec-one ">
            <div className="container">
              <h3>Case STUDY </h3>
              <ScrollAnimation animateIn="slideInUp" animateOnce="true">
              <h1>ALLTHATGROWS</h1>
              </ScrollAnimation>
              <p className="ft-sndry">
                How CueBlocks helped AllThatGrows in leveraging the Amazon
                Marketplace as an additional source of consistently growing
                revenue
              </p>

              <div className="ww-top-img ">
                <img src={Amazon1} alt="Amazon" />
              </div>
              <div className="ww-main-heading">
                An exclusive e-commerce store on Shopify, providing pure &
                organic seeds of various vegetables, flowers, microgreens,
                exotic herbs & edible flowers.
              </div>
              <h5>
                For more information, visit:{" "}
                <a href="https://www.allthatgrows.in/" target="_blank" rel="noopener noreferrer">
                  www.AllThatGrows.in.
                </a>
              </h5>
            </div>
          </div>

          <div className="increase-venue ">
            <div className="container">
              <div className="revenue-outer">
                <div className="venue-left">
                  <img src={Plant} alt="Amazon" />
                </div>
                <div className="venue-right">
                  <h3> 42.04% increase in revenue from Amazon </h3>
                  <h3> 24.50% increase in Orders</h3>
                  <p>Comparing Jan 19 - Aug 19 with previous year </p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-situation ">
            <div className="container">
              <div className="top-details text-center">
                <h2 className="heading_main">CLIENT’S Situation</h2>
                <p>
                  Since 2017, AllThatGrows has been offering superior quality,
                  non-hybrid, pure, open-pollinated, GMO-free, and heirloom
                  seeds. Stepping into unchartered territory, it was challenging
                  to generate sales with limited brand awareness in the market.
                  Our primary focus for AllThatGrows was to look for a platform
                  that could give us both - sales and a positive ROI.{" "}
                </p>
              </div>

              <div className="situation-block">
                <div className="situation-block-left ">
                  <ScrollAnimation animateIn="bounceInLeft" animateOnce="true">
                  <img src={Appscreen} alt="Amazon" />
                  </ScrollAnimation>
                </div>
                <div className="situation-block-right">
                  <h4>
                    We suggested AllThatGrows to use marketplaces like Amazon,
                    Flipkart, Shopclues and PayTm.{" "}
                  </h4>
                  <p>
                    AllThatGrows was ready to diversify and expand its business
                    onto multiple Marketplaces/CSEs and needed a team that
                    understood the business model and could help manage the
                    needs, requirements, and updates of each marketplace.{" "}
                  </p>
                  <p>
                    AllThatGrows wanted to increase its prominence and
                    visibility in different CSEs/Marketplaces, reach a wider web
                    audience, and increase their overall sales revenue while
                    keeping the spend within their marketing budget.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="strategy ">
            <div className="container">
              <div className="strategy-bloock">
                <div className="strategy-bloock-left">
                  <h2 className="heading_main">Strategy</h2>
                  <p>
                    With specific inputs from the client, AllThatGrows, we
                    formed a detailed Marketplace Management Strategy that took
                    care of each and every requirement of the business. Due to
                    the diverse seasonal change in the country, the sales of
                    seeds would often vary each month.{" "}
                  </p>
                  <p>
                    The cost per sale would oscillate between{" "}
                    <span>20% to 90%,</span> which made the business lucrative
                    in one month but unrewarding in the next.{" "}
                  </p>
                  <h4>
                    Amazon’s Cost per Purchase model was ideal for a business
                    like AllThatGrows because it gave us the liberty to pay for
                    just the sales and not the clicks.{" "}
                  </h4>
                </div>
                <div className="strategy-bloock-right">
                  <ScrollAnimation animateIn="bounceInDown" animateOnce="true">
                  <img
                    src={Moving_img1}
                    alt="Amazon"
                    className="image-moving"
                  />
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>

          <div className="challenge ">
            <div className="container">
              <div className="top-details text-center">
                <h2 className="heading_main">Challenges</h2>
              </div>
              <div className="challnege-bloock">
                <div className="challnege-bloock-left">
                  <div className="single-block">
                    <span>01</span>
                    <div className="detail-challnege">
                      <p>
                        <strong>Non-profitable FBA -</strong> Amazon offers all
                        its e-retailers an option of storing their products in
                        the Amazon facilities, known as “Fulfillment by Amazon’,
                        which makes the cost of shipping almost negligible.
                        Despite using the FBA privilege, AllThatGrows continued
                        to generate inadequate sales revenue.
                      </p>
                    </div>
                  </div>
                  <div className="single-block">
                    <span>02</span>
                    <div className="detail-challnege">
                      <p>
                        <strong>Sales Conversion -</strong> AllThatGrows, like
                        any other business, needed the sales to convert into
                        sizeable profits in order for it to expand its reach.
                        But that was not possible with just a few clicks and
                        views. After all, if a customer just visits your
                        website, looks at the product but doesn’t buy anything,
                        it doesn’t serve the purpose of running a business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="challnege-bloock-left">
                  <div className="single-block">
                    <span>03</span>
                    <div className="detail-challnege">
                      <p>
                        <strong>Brand Awareness -</strong> Being a relatively
                        new venture, the most common obstacle that came our way
                        with AllThatGrows was a limited brand awareness among
                        the audience.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="single-block">
                    <img src={Challenge_sec} alt="Amazon" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ww-sec-points  process-undertaken">
            <div className="container">
              <h2 className="heading_main">
                Process UNDERTAKEN <br />
                BY CUEBLOCKS{" "}
              </h2>

              <ul>
                <li className="process1">
                  <span className="img-block"></span>
                  <p>
                    We initiated the process by establishing a Marketplace
                    Management Program for AllThatGrows that began with an
                    understanding of the product feed formatting on each
                    marketplace. Starting with obtaining GTIN Exemption for the
                    products we deal in, we moved on to add the products on the
                    marketplace. We collected the images for each product and
                    re-sized them according to Amazon’s guidelines.{" "}
                  </p>
                </li>
                <li className="process2">
                  <span className="img-block"></span>
                  <p>
                    Thereafter, we kept a close eye on the allocated budget,
                    costs, and revenues, adjusting each parameter to gain a
                    positive ROI-driven ranking for all the products. We
                    optimized the product titles, features, descriptions in such
                    a way that the customers could easily discern the USP of the
                    products.{" "}
                  </p>
                </li>
                <li className="process3">
                  <span className="img-block"></span>
                  <p>
                    Due to the low cost of the products, FBA did not prove
                    fruitful enough for AllThatGrows. So, we decided that
                    instead of using the Amazon facilities to store our products
                    for shipment, we chose to ship the products on our own.
                  </p>
                </li>
                <li className="process4">
                  <span className="img-block"></span>
                  <p>
                    Counting on our experience in various marketplaces, we
                    started optimizing the content for AllThatGrows. Once the
                    content gained some traction, we created paid campaigns
                    (sponsored programs) to generate more sales revenue. Optimal
                    keyword research was done and implemented for Amazon.
                  </p>
                </li>
              </ul>
              <h6>
                As AllThatGrows gained brand recognition, we saw a significant
                rise in sales conversion as well. An excellent customer support
                team at AllThatGrows helped in creating a unique brand identity
                that understood and resolved customer queries with utmost
                efficiency.{" "}
              </h6>
            </div>
          </div>

          <div className="ww-sec-category ww-sec-common  amazon-sucess">
            <div className="container">
              <div className="ww-table-info ww-category-info ">
                <h3>Successes</h3>
                <div className="sucess-story">
                  <div className="sucess-block">
                    Over the course of the years, AllThatGrows has experienced a
                    remarkable increase in orders and the sales revenue
                    generated from them. Even today, CueBlocks works
                    hand-in-hand with AllThatGrows to help them in their journey
                    of exponential growth.
                  </div>
                  <div className="sucess-block">
                    Our data shows that our strategy of efficient optimization
                    drove incremental conversions and revenue forAllThatGrows.
                  </div>
                </div>
                <div className="sucees-table">
                  <table>
                    <thead>
                      <tr>
                        <td>
                          <span>AUG 2017</span>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <span>AUG 2019</span>
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="extra">
                        <td colSpan="3" height="60"></td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            242<u>ORDERS</u>
                          </span>
                        </td>
                        <td rowSpan="2">
                          <img src={Arrow_wh} alt="Amazon" />
                        </td>
                        <td className="right">
                          <span>
                            629 <u>ORDERS</u>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            41,737 <u>REVENUE</u>
                          </span>
                        </td>
                        <td className="right">
                          <span>
                            1,26,369 <u>REVENUE</u>
                          </span>
                        </td>
                      </tr>
                      <tr className="extra">
                        <td colSpan="3" height="60"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <h6>
                The overall contribution generated from the paid campaigns was
                about <strong>1/3rd</strong> of the sales from Amazon.
              </h6>
            </div>
          </div>

          <div className="get-in-touch-casestudy-section">
            <h3>
              Reach out to us to understand
              <br /> how we can add value to your business.
            </h3>
            <a href="/contact" className="main_cta">
              Get In Touch
            </a>
          </div>

          <div id="scroll-to" className="case-scroll">
            <a href="#" title="Scroll To Top">
              <img src={Scroll_top_img} alt="Scroll To Top" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <Clutch />
  </Layout>
)